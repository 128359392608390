import { useRef, useEffect } from 'react';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';

export const useStickyPosition = (withFullWidthBottomShadow: boolean) => {
  const { headerContentRef } = useHeaderContext();
  const stickyRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const headerElement = headerContentRef.current;

    if (!headerElement) return;

    const updateStickyPosition = (entries: ResizeObserverEntry[]) => {
      const entry = entries[0];
      if (stickyRef.current && entry) {
        const additionalOffset = withFullWidthBottomShadow ? 0 : 16;
        stickyRef.current.style.top = `${entry.contentRect.height + additionalOffset}px`;
      }
    };

    const resizeObserver = new ResizeObserver(updateStickyPosition);

    resizeObserver.observe(headerElement);

    return () => {
      resizeObserver.disconnect();
    };
  }, [headerContentRef, withFullWidthBottomShadow, stickyRef.current]);

  return stickyRef;
};
