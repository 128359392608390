import React, { useMemo } from 'react';
import { AdBlock } from '@components/AdBlock/AdBlock';
import { AD_BLOCK_DIMENSIONS } from '@commons/dfp';
import { adBlockTargeting } from '@features/SearchPage/SearchPage.constants';

interface AdBlockHeaderProps {
  id: string;
}

export const AdBlockHeader = ({ id }: AdBlockHeaderProps) => {
  const targetingAdBlock = useMemo(
    () => [
      { key: 'pt', value: 'browse' },
      { key: 'id', value: id },
    ],
    [id],
  );

  return (
    <>
      <AdBlock
        adBlockId="CategoryNote"
        targeting={targetingAdBlock}
        collapseConfig={{
          collapseEnabled: true,
          heights: { dekstop: 90, mobile: 50 },
        }}
      />
      <AdBlock
        adBlockId="BrowseTop1"
        targeting={targetingAdBlock}
        collapseConfig={{
          collapseEnabled: true,
          heights: { dekstop: 90, mobile: 50 },
        }}
      />
    </>
  );
};

export const AdBlockDFPHeader = () => {
  return (
    <AdBlock
      adBlockId="DFPSystemMessage"
      adBlockDimensions={{ desktop: AD_BLOCK_DIMENSIONS.L, mobile: AD_BLOCK_DIMENSIONS.M }}
    />
  );
};

export const AdBlockSearchHeader = () => {
  return (
    <AdBlock
      adBlockId="PPSearchContent"
      targeting={adBlockTargeting}
      collapseConfig={{
        collapseEnabled: true,
        heights: { dekstop: 90, mobile: 50 },
      }}
    />
  );
};

export const AdBlockHomePageHeader = () => {
  return (
    <>
      <AdBlock
        adBlockId="HPFullTopBar"
        adBlockDimensions={{ desktop: AD_BLOCK_DIMENSIONS.L, mobile: AD_BLOCK_DIMENSIONS.M }}
        collapseConfig={{
          collapseEnabled: true,
          heights: { dekstop: 85, mobile: 85 },
        }}
      />
      <AdBlock
        adBlockId="HPMainTopBar"
        adBlockDimensions={{ desktop: AD_BLOCK_DIMENSIONS.L, mobile: AD_BLOCK_DIMENSIONS.M }}
      />
      <AdBlock
        adBlockId="HPMob01"
        adBlockDimensions={{ desktop: AD_BLOCK_DIMENSIONS.L, mobile: AD_BLOCK_DIMENSIONS.M }}
      />
      <AdBlock
        adBlockId="HPMob02"
        adBlockDimensions={{ desktop: AD_BLOCK_DIMENSIONS.L, mobile: AD_BLOCK_DIMENSIONS.M }}
      />
      <AdBlock
        adBlockId="HPMob03"
        adBlockDimensions={{
          desktop: AD_BLOCK_DIMENSIONS.L,
          mobile: AD_BLOCK_DIMENSIONS.M,
        }}
      />
    </>
  );
};

export const AdBlockHomePageFooter = () => {
  return (
    <AdBlock
      adBlockId="HPMob04"
      adBlockDimensions={{ desktop: AD_BLOCK_DIMENSIONS.L, mobile: AD_BLOCK_DIMENSIONS.M }}
    />
  );
};
