import { useMemo } from 'react';
import { getCookie } from 'cookies-next';
import { NoResultsWithReload } from '@components/NoResultsWithReload/NoResultsWithReload';
import { ModuleListCreator } from '@components/Modules/ModuleListCreator/ModuleListCreator';
import { ProductTileSkeleton } from '@components/Skeletons/ProductTileSkeleton/ProductTileSkeleton';
import { SkeletonWrapper } from '@components/Skeletons/SkeletonWrapper/SkeletonWrapper';
import { ReorderProductCarouselModule } from '@components/Modules/ReorderProductCarouselModule/ReorderProductCarouselModule';
import { COOKIES_LIST } from '@commons/cookies';
import { useAuthContext } from '@modules/auth/context';
import { DEFAULT_SECTIONS_TO_LOAD, MAX_SECTIONS_TO_LOAD } from '@constants/sectionsToLoad';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { useHomeFeed } from './useHomeFeed';
import { useReorderModule } from './useReorderModule';

export const HomePageFeed = () => {
  const { loadingInit, loadingMore, initData, moreData } = useHomeFeed();
  const { data: reorderModule, isReorderModuleLoading } = useReorderModule();
  const { userInfo } = useAuthContext();
  const { skeletonPageSize } = useHeaderContext();
  const startPosition = reorderModule ? 1 : 0;

  const isEmptyPage = useMemo(
    () => !initData?.length && !reorderModule && !moreData,
    [initData, moreData, reorderModule],
  );

  const shouldShowReorderModule = useMemo(
    () => userInfo?.memberSinceYear && getCookie(COOKIES_LIST.IS_SAME_USER) !== undefined,
    [userInfo],
  );

  if (loadingInit) {
    return (
      <SkeletonWrapper
        count={DEFAULT_SECTIONS_TO_LOAD * skeletonPageSize}
        skeleton={<ProductTileSkeleton />}
      />
    );
  }

  if (isEmptyPage) {
    return <NoResultsWithReload />;
  }

  return (
    <>
      {shouldShowReorderModule && isReorderModuleLoading && (
        <SkeletonWrapper count={skeletonPageSize} skeleton={<ProductTileSkeleton />} />
      )}

      {shouldShowReorderModule && reorderModule && !isReorderModuleLoading && (
        <ReorderProductCarouselModule module={reorderModule} position={startPosition} />
      )}

      <ModuleListCreator
        data={initData}
        reorderModuleId={reorderModule?.id}
        startPosition={startPosition}
      />

      {loadingMore && (
        <SkeletonWrapper
          count={(MAX_SECTIONS_TO_LOAD - DEFAULT_SECTIONS_TO_LOAD) * skeletonPageSize}
          skeleton={<ProductTileSkeleton />}
        />
      )}

      {!loadingMore && moreData.length > 0 && (
        <ModuleListCreator
          data={moreData}
          reorderModuleId={reorderModule?.id}
          startPosition={startPosition + initData.length}
        />
      )}
    </>
  );
};
