import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useScrollingContext } from '@context/ScrollingContext/ScrollingContext';
import { useProductListContext } from '@context/ProductListContext';
import { CanonicalLink } from '@components/CanonicalLink/CanonicalLink';
import { ProductListLocationProvider } from '@modules/ga/context/location';
import { useLocalization } from '@hooks/useLocalization';
import { StandingOrderAlert } from '@components/StandingOrderAlert/StandingOrderAlert';
import {
  AdBlockDFPHeader,
  AdBlockHomePageFooter,
  AdBlockHomePageHeader,
} from '@components/AdBlockHeader/AdBlockHeader';
import { StickyContainer } from '@components/StickyContainer/StickyContainer';
import { useStickyPosition } from '@hooks/useStickyPosition';
import { channels } from '@modules/ga/context/constants';
import { HomePageShopBy } from './components/HomePageShopBy/HomePageShopBy';
import { HomePageFeed } from './components/HomePageFeed/HomePageFeed';
import styles from './HomePage.module.scss';

export const HomePage = () => {
  const { t } = useLocalization();
  const { setChannel } = useProductListContext();
  const { isScrolling } = useScrollingContext();
  const [isExpandedShopBy, setIsExpandedShopBy] = useState(false);
  const stickyRef = useStickyPosition(isExpandedShopBy);

  useEffect(() => {
    setChannel(channels.homePage);
  }, [setChannel]);

  return (
    <>
      <CanonicalLink />
      <ProductListLocationProvider location="homepage">
        <div className={cx({ ['hidden']: isScrolling })}>
          <AdBlockDFPHeader />
          <StandingOrderAlert />
          <AdBlockHomePageHeader />
        </div>

        <StickyContainer
          withBackground={isExpandedShopBy}
          withFullWidthBottomShadow={isExpandedShopBy}
          ref={stickyRef}
          className={styles.sticky_container}
        >
          <HomePageShopBy
            isSticky={isScrolling}
            isExpanded={isExpandedShopBy}
            setIsExpanded={setIsExpandedShopBy}
          />
        </StickyContainer>

        <Typography variant="h1" sx={visuallyHidden}>
          {t('common:homePage')}
        </Typography>

        <HomePageFeed />

        <AdBlockHomePageFooter />
      </ProductListLocationProvider>
    </>
  );
};
