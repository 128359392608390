import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
  useRef,
  RefObject,
} from 'react';
import { Grid, IconButton } from '@mui/material';
import Link from 'next/link';
import cx from 'classnames';
import uniqueId from 'lodash/uniqueId';
import { useNavigationData } from '@hooks/header/useNavigationData';
import { ExpressToggle } from '@components/ExpressToggle/ExpressToggle';
import { useExpressContext } from '@context/ExpressContext/ExpressContext';
import { useLocalization } from '@hooks/useLocalization';
import { Button } from '@components/UI';
import {
  arrowRight as ArrowRightIcon,
  close as CloseIcon,
  arrowDown as ArrowDownIcon,
  arrowUp as ArrowUpIcon,
} from '@assets/icons/system';
import { ExpressIcon } from '@components/UI/ExpressIcon/ExpressIcon';
import { trackShopBy, trackShopByExpress } from '../../utils';
import styles from './HomePageShopBy.module.scss';

interface HomePageShopByProps {
  isExpanded: boolean;
  isSticky: boolean;
  setIsExpanded: Dispatch<SetStateAction<boolean>>;
}

const updateMoreItems = (
  shopByRef: RefObject<HTMLUListElement | null>,
  shopByContainerEntry: ResizeObserverEntry,
  setShowMoreItems: Dispatch<SetStateAction<boolean>>,
  isShowMoreItemsExpanded: boolean,
) => {
  if (!shopByRef.current) return;

  const containerWidth = shopByContainerEntry.contentRect.width;
  const listRect = shopByRef.current.getBoundingClientRect();

  if (containerWidth < listRect.width) {
    setShowMoreItems(true);
    let hasHiddenItems = false;
    shopByRef.current.querySelectorAll('li').forEach((li) => {
      const liRect = li.getBoundingClientRect();
      if (liRect.width + liRect.x > containerWidth || hasHiddenItems) {
        hasHiddenItems = true;
        li.classList.add(styles.hide);
      }
    });
  } else if (containerWidth >= listRect.width && !isShowMoreItemsExpanded) {
    setShowMoreItems(false);
  }
};

export const HomePageShopBy = ({ isExpanded, isSticky, setIsExpanded }: HomePageShopByProps) => {
  const { t } = useLocalization();
  const { secondaryNavigation } = useNavigationData();
  const { eligible, enabled: expressEnabled } = useExpressContext();
  const [titleId] = useState(() => uniqueId('shopBy_title_'));
  const [showMoreItems, setShowMoreItems] = useState(false);
  const [isShowMoreItemsExpanded, setIsShowMoreItemsExpanded] = useState(false);
  const shopByContainerRef = useRef<HTMLDivElement | null>(null);
  const shopByRef = useRef<HTMLUListElement | null>(null);

  const handleExpandShopBy = useCallback(() => {
    setIsExpanded(true);
  }, [setIsExpanded]);

  const handleCollapseShopBy = useCallback(() => {
    setIsExpanded(false);
  }, [setIsExpanded]);

  const handleExpressToggle = useCallback(() => {
    trackShopByExpress(!expressEnabled);
  }, [expressEnabled]);

  const handleShopByClick = useCallback((itemName?: string | null) => {
    trackShopBy(itemName);
  }, []);

  const resetHideItems = useCallback(() => {
    shopByRef.current?.querySelectorAll('li').forEach((li) => li.classList.remove(styles.hide));
  }, []);

  const toggleShowMoreItems = useCallback(() => {
    if (!isShowMoreItemsExpanded) {
      resetHideItems();
    }
    setIsShowMoreItemsExpanded((prev) => !prev);
  }, [isShowMoreItemsExpanded, resetHideItems]);

  const renderArrowIcon = useCallback(() => {
    return isShowMoreItemsExpanded ? (
      <ArrowUpIcon width={10} height={10} />
    ) : (
      <ArrowDownIcon width={10} height={10} />
    );
  }, [isShowMoreItemsExpanded]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [expressEnabled]);

  useEffect(() => {
    const shopByElement = shopByContainerRef.current;
    if (!shopByElement) return;

    const resizeObserver = new ResizeObserver((entries) => {
      const [shopByContainerEntry] = entries;
      resetHideItems();
      updateMoreItems(shopByRef, shopByContainerEntry, setShowMoreItems, isShowMoreItemsExpanded);
    });

    resizeObserver.observe(shopByElement);

    return () => {
      resizeObserver.disconnect();
    };
  }, [resetHideItems, isShowMoreItemsExpanded, secondaryNavigation]);

  if (isSticky && !isExpanded) {
    return (
      <Grid
        className={cx(styles.container, styles.mobile_container)}
        data-testid="landing-shop-by"
        container
      >
        <Button
          variant="outlined"
          onClick={handleExpandShopBy}
          className={cx(styles.shop_by_button, {
            [styles.express_enabled]: expressEnabled,
          })}
        >
          <span className={styles.shop_by_button_text}>{t('filters.shopBy')}</span>
          {expressEnabled && <ExpressIcon className={styles.shop_by_button_express} />}
          <ArrowRightIcon className={styles.shop_by_button_arrow_icon} width={12} height={12} />
        </Button>
      </Grid>
    );
  }

  return (
    <div className={styles.wrapper}>
      <Grid
        className={styles.container}
        data-testid="landing-shop-by"
        container
        ref={shopByContainerRef}
      >
        <ul
          ref={shopByRef}
          className={cx(styles.items, {
            [styles.show_more_items]: isShowMoreItemsExpanded,
          })}
        >
          <li className={styles.shop_by_title} id={titleId}>
            {t('filters.shopBy')}
          </li>
          {eligible && (
            <li className={styles.item}>
              <ExpressToggle className={styles.express} shopBy onToggle={handleExpressToggle} />
            </li>
          )}
          {secondaryNavigation.map((item, index) => {
            const categoryId = `shopBy_category_${index}`;
            const Icon = item?.icon;
            return (
              <li className={styles.item} key={item?.name}>
                <Link
                  prefetch={false}
                  href={item?.link || ''}
                  onClick={() => handleShopByClick(item?.name)}
                  className={cx(styles.secondary_navigation_link, {
                    [styles.with_icon]: !!item?.icon,
                  })}
                  aria-labelledby={`${titleId} ${categoryId}`}
                  id={categoryId}
                >
                  {Icon && <Icon />}
                  {item?.name}
                </Link>
              </li>
            );
          })}
        </ul>
      </Grid>
      {showMoreItems && (
        <IconButton
          className={cx(styles.secondary_navigation_link, styles.show_more_items_button)}
          onClick={toggleShowMoreItems}
          aria-label="expand"
        >
          {renderArrowIcon()}
        </IconButton>
      )}
      {isSticky && isExpanded && (
        <IconButton
          className={cx(styles.secondary_navigation_link, styles.close_button)}
          onClick={handleCollapseShopBy}
          aria-label="close"
        >
          <CloseIcon width={10} height={10} />
        </IconButton>
      )}
    </div>
  );
};
